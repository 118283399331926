<script setup lang="ts">
interface Props {
  position?: 'left' | 'center' | 'right'
  lineClass?: string
  labelClass?: string
}

const props = withDefaults(defineProps<Props>(), {
  position: 'center'
})
</script>

<template>
  <div class="inline-flex items-center justify-center relative w-full min-w-max bg-inherit">
    <hr class="w-full h-px my-2 bg-black-10 border-0" :class="[props.lineClass]">
    <span
      class="absolute text-body-regular-12 text-black-50 bg-inherit"
      :class="{
        'pr-3 left-0': props.position === 'left',
        'px-3': props.position === 'center',
        'pl-3 right-0': props.position === 'right',
        [props.labelClass as string]: true
      }"
    >
      <slot />
    </span>
  </div>
</template>

<style scoped lang="postcss">

</style>
